import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../image/hanoilogo.png"
export default function Footer() {
    const {t, i18n} = useTranslation();
    const language = i18n.language
    const footer = t("footer.branch", {returnObjects: true})
    return(
        <>
 <div className="footer">
 <div className="footer__container">
     <div className="container">
        <div className="row">
            <div className="col-md-3">
            <img src={logo} alt="logo" style={{width: 200, height:200}}/>
            </div>
            <div className="col-md-3">
                <div className="bold-white">M Village Ho Guom Hotel</div>
                <div className="footer-text"><i class="fa-solid fa-location-dot"></i> 38 Hai Ba Trung, Trang Tien, Hoan Kiem, Ha Noi</div>
                <div className="footer-text"><i class="fa-solid fa-phone"></i> <a style={{color: '#fff'}} href="tel:+84.915.223.355">+84.915.223.355</a></div>
                <div className="footer-text"><i class="fa-solid fa-envelope"></i> <a style={{color: '#fff'}} href="mailto:it3@azumayavietnam.com">info@hoguomhotel.com</a></div>
            </div>
            <div className="col-md-3">
                <div className="bold-white">HOTEL POLICY</div>
                <div className="footer-text"><Link style={{color: '#fff'}} to = '/'>Term & Condition</Link></div>
                <div className="footer-text"><Link style={{color: '#fff'}}to = '/'>Location & Map</Link></div>
            </div>
            <div className="col-md-3">
                <div className="bold-white">NEED TO KNOW</div>
                <div className="footer-text"><Link style={{color: '#fff'}}to = '/'>Know before visiting Hanoi</Link></div>
                <div className="footer-text"><Link style={{color: '#fff'}}to = '/'>Things to do in Hanoi</Link></div>
            </div>
        </div>
         </div>
     </div>

 <div className="footer__container3">
             <div className="container">
                 <div className="row" style={{textAlign:'center'}}>
                     <div className="col-md-12">
             <div className="footer__copyright">
                 <p className="mb-0">Copyright © 2024 - Azumaya - All Right Reversed</p>
             </div>
         </div>
         </div>
         </div>
         </div>
 </div>
        </>
       
    )
}
