import React from "react";
import Booking from "../Units/Booking";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Flatpickr from "react-flatpickr";
import Button from "react-bootstrap/Button";
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import { format } from "date-fns";
import { sendMassageRequest } from "../../api/reservation";
import ScrollToTop from "../../units/ScrollToTop";
import '../../index.css'
export default function VietnamService({deviceType}) {
 
  const navigate = useNavigate()
  const {t, i18n} = useTranslation()
  const location = useLocation();
  const language = i18n.language
  const [hover, setHover] = useState({service1: false, service2: false, service3: false, service4: false, service5: false})

  return (
    <>
    {/* <HelmetLayout title={title} /> */}
      <div className="service__header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Service</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="re__breadcrumb">
              <ul className="breadcrumb__list">
                <li className="breadcrumb__item">
                  <Link to={`/${language}/`}>
                    <i className="fa-solid fa-house" />
                  </Link>
                </li>
                <li className="breadcrumb__item">/</li>
                <li className="breadcrumb__item">
                  <Link className="breadcrumb__title" to={`/${language}/service/`}>
                    {t('header.service')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3 p-0 mb-5">
            <div className="service-container" onMouseEnter={() => setHover({service1: true})} onMouseLeave={() => setHover({service1: false})}>
              {!hover.service1 && (
              <div className="d-flex flex-column justify-content-center"  style={{position:'absolute', bottom: 0,  background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))", width: 300, height: 100}}>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '2.5rem', fontWeight:'bold'}}>Service 1</div>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '1.4rem'}}>View More <i class="fa-solid fa-chevron-right"></i></div>
              </div>
              )}
            <div className={`hover-text ${hover.service1 ? 'fade-in' : 'fade-out'}`} style={{position:'absolute', top: 0, zIndex:1, backgroundColor: "rgba(255,255,255, 0.8)", width: '100%', height: '100%'}}>
              <div style={{ marginLeft: 40, marginTop:20}}>
              <div style={{color: '#000071', fontSize: '3rem', fontWeight:'bold'}}>Service 1</div>
              <div className="col-md-4 p-0 d-flex justify-content-center align-items-center read-more-2" style={{marginTop: 30}}>
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View More <i class="fa-solid fa-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
              </div>
              </div>
            </div>
           
          </div>
          <div className="col-md-3 p-0 mb-5">
            <div className="service-container" onMouseEnter={() => setHover({service2: true})} onMouseLeave={() => setHover({service2: false})}>
              {!hover.service2 && (
              <div className="d-flex flex-column justify-content-center"  style={{position:'absolute', bottom: 0,  background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))", width: 300, height: 100}}>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '2.5rem', fontWeight:'bold'}}>Service 1</div>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '1.4rem'}}>View More <i class="fa-solid fa-chevron-right"></i></div>
              </div>
              )}
            <div className={`hover-text ${hover.service2 ? 'fade-in' : 'fade-out'}`} style={{position:'absolute', top: 0, zIndex:1, backgroundColor: "rgba(255,255,255, 0.8)", width: '100%', height: '100%'}}>
              <div style={{ marginLeft: 40, marginTop:20}}>
              <div style={{color: '#000071', fontSize: '3rem', fontWeight:'bold'}}>Service 1</div>
              <div className="col-md-4 p-0 d-flex justify-content-center align-items-center read-more-2" style={{marginTop: 30}}>
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View More <i class="fa-solid fa-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
              </div>
              </div>
            </div>
           
          </div>
          <div className="col-md-3 p-0 mb-5">
            <div className="service-container" onMouseEnter={() => setHover({service3: true})} onMouseLeave={() => setHover({service3: false})}>
              {!hover.service3 && (
              <div className="d-flex flex-column justify-content-center"  style={{position:'absolute', bottom: 0,  background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))", width: 300, height: 100}}>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '2.5rem', fontWeight:'bold'}}>Service 1</div>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '1.4rem'}}>View More <i class="fa-solid fa-chevron-right"></i></div>
              </div>
              )}
            <div className={`hover-text ${hover.service3 ? 'fade-in' : 'fade-out'}`} style={{position:'absolute', top: 0, zIndex:1, backgroundColor: "rgba(255,255,255, 0.8)", width: '100%', height: '100%'}}>
              <div style={{ marginLeft: 40, marginTop:20}}>
              <div style={{color: '#000071', fontSize: '3rem', fontWeight:'bold'}}>Service 1</div>
              <div className="col-md-4 p-0 d-flex justify-content-center align-items-center read-more-2" style={{marginTop: 30}}>
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View More <i class="fa-solid fa-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
              </div>
              </div>
            </div>
           
          </div>
          <div className="col-md-3 p-0 mb-5">
            <div className="service-container" onMouseEnter={() => setHover({service4: true})} onMouseLeave={() => setHover({service4: false})}>
              {!hover.service4 && (
              <div className="d-flex flex-column justify-content-center"  style={{position:'absolute', bottom: 0,  background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))", width: 300, height: 100}}>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '2.5rem', fontWeight:'bold'}}>Service 1</div>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '1.4rem'}}>View More <i class="fa-solid fa-chevron-right"></i></div>
              </div>
              )}
            <div className={`hover-text ${hover.service4 ? 'fade-in' : 'fade-out'}`} style={{position:'absolute', top: 0, zIndex:1, backgroundColor: "rgba(255,255,255, 0.8)", width: '100%', height: '100%'}}>
              <div style={{ marginLeft: 40, marginTop:20}}>
              <div style={{color: '#000071', fontSize: '3rem', fontWeight:'bold'}}>Service 1</div>
              <div className="col-md-4 p-0 d-flex justify-content-center align-items-center read-more-2" style={{marginTop: 30}}>
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View More <i class="fa-solid fa-chevron-right"></i>
                           </span> 
                           </Link>
                      </div>
              </div>
              </div>
            </div>
           
          </div>
          <div className="col-md-3 p-0 mb-5">
            <div className="service-container" onMouseEnter={() => setHover({service5: true})} onMouseLeave={() => setHover({service5: false})}>
              {!hover.service5 && (
              <div className="d-flex flex-column justify-content-center"  style={{position:'absolute', bottom: 0,  background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))", width: 300, height: 100}}>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '2.5rem', fontWeight:'bold'}}>Service 1</div>
                <div style={{color: '#fff', marginLeft: 10, fontSize: '1.4rem'}}>View More <i class="fa-solid fa-chevron-right"></i></div>
              </div>
              )}
            <div className={`hover-text ${hover.service5 ? 'fade-in' : 'fade-out'}`} style={{position:'absolute', top: 0, zIndex:1, backgroundColor: "rgba(255,255,255, 0.8)", width: '100%', height: '100%'}}>
              <div style={{ marginLeft: 40, marginTop:20}}>
              <div style={{color: '#000071', fontSize: '3rem', fontWeight:'bold'}}>Service 1</div>
              <div className="col-md-4 p-0 d-flex justify-content-center align-items-center read-more-2" style={{marginTop: 30}}>
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View More <i class="fa-solid fa-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
              </div>
              </div>
            </div>
           
          </div>
          
          
        </div>
      </div>
    </>
  );
}
