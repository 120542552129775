import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css'
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter} from 'react-router-dom';
import FontProvider from './FontProvider';
import DisableArrowScroll from './DisableScroll';
const root = ReactDOM.createRoot(document.getElementById('root'));
import i18n from'./i18n'
import { I18nextProvider } from 'react-i18next';
import DisableTouchPadHorizontalScroll from './DisableTouchPadScroll';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from './userProvider';
root.render(
  <UserProvider>
   <GoogleOAuthProvider clientId="795120460818-jc17k3sa5aslgr8ljtu3hlrmbqlcrl73.apps.googleusercontent.com">
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <FontProvider>
              <DisableArrowScroll>
                <DisableTouchPadHorizontalScroll>
    {/* <React.StrictMode> */}
                  <App />
  {/* </React.StrictMode> */}
                </DisableTouchPadHorizontalScroll>
              </DisableArrowScroll>
            </FontProvider>
        </I18nextProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
    </UserProvider>
  
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
