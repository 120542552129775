// src/Collapsible.js
import React, { useState, useRef, useEffect } from 'react';

const Collapsible = ({ title, icon, width, height, marginRight, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = '0px';
    }
  }, [isOpen]);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-1">
      <div className="collapsible__button-1" onClick={toggleCollapsible}>
       <img src = {icon} style={{width: width, height: height, marginRight: marginRight}} /> {title}
      </div>
      <div className="collapsible__content-1" ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
