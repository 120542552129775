import React from "react";
import {useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HelmetLayout from "../HelmetLayout/HelmetLayout";
import ScrollToTop from "../../units/ScrollToTop";
import promotion from "../../image/promotion.jpg"
import BookingMobile from "../../container/Units/BookingMobile";
import Booking from "../../container/Units/Booking";
export default function Gallery() {
  const { t, i18n} = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state;

  const [startDate, setStartDate] = useState(receivedData ? receivedData.startDate : '');
  const [endDate, setEndDate] = useState(receivedData ? receivedData.endDate : '');

  const handleContinue = (selectedRoom) => {
    const data = {
      ...receivedData,
      startDate,
      endDate,
      selectedCity,
      selectedBranch,
      selectedRoom
    };
    navigate(`/${language}/reservation/`, { state: data });
  };

  const a = t("branch.dn")
  const b = t("header.title2")
  const c = a + " | "+ b
  return (
    <>
    <ScrollToTop x={0} y={1200} />
    {/* <HelmetLayout title={c}/> */}
    <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Gallery</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
    <BookingMobile />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house" />
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title" to ={`/${language}/gallery/`}>
                  Gallery
                </Link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-2">
              <img src ={promotion} />
          </div>
        </div>
      </div>
        
    </>
  );
}
