import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import HelmetLayout from "../HelmetLayout/HelmetLayout";
import Booking from "../../container/Units/Booking";
export default function AboutUs() {

    const {t} = useTranslation()
    const a = t("thankyou_contract.name")
    const b = t("header.title2")
    const c = a + " | "+ b

    return(
      <>
      <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>About Us</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
      <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to="/">
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title">About Us</Link>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
        <div className="container">
            <div className="row">
                <div className="col-md-12 pre-line">
                  <div style={{fontSize: '1.6rem',fontWeight:'bold', color:'#000071'}}>WELCOME TO ACOUSTIC HOTEL & SPA</div>
                  <div style={{fontSize: '1.4rem'}}>
                  WELCOME TO ACOUSTIC HOTEL & SPA
                  <br/><br/>Located in the middle of French Quarter, Ha Noi Old Quarter and governmental area, as well as the famous shopping and entertainment areas, this trendy hotel’s location is ideal for shopping, entertainment, leisure travel and business trip purpose.

                  <br/><br/>With 75 modern and stylishly designed rooms, Acoustic Hotel & Spa has what every discerning traveler is looking for. From its floor to ceiling windows with panoramic views of the sprawling metropolis of Ha Noi to its comfortable ambiance and luxury neo-classical design.

                  <br/><br/>The Acoustic Hotel & Spa provides a multitude of drinking and dining options on-site, including Indigo Restaurant, Eclipse Sky Bar – one of the best rooftop bars in Ha Noi, ideal for both relaxed yet stylish special dining venue for friends to enjoy some fine wines & cocktails.
                  </div>
                </div>
            </div>
        </div>
      </>
    )
}
