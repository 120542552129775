import { Link, useParams } from "react-router-dom";
import Booking from "../Units/Booking";
import { useTranslation } from "react-i18next"; 
import { useEffect} from 'react';
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";


export default function Feature() {
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en'
 
    const a = "Ho Guom Hotel"
    const b = "Special Package"
    const c = a + " | "+ b
  return (
    <div>
      <HelmetLayout title={c} />
      <div className="policies__header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Special Package</h1>
            </div>
          </div>
        </div>
      <div>
        <Booking />
      </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="re__breadcrumb">
              <ul className="breadcrumb__list">
                <li className="breadcrumb__item">
                  <i className="fa-solid fa-house"></i>
                  <Link to="/"></Link>
                </li>
                <li className="breadcrumb__item">/</li>
                <li className="breadcrumb__item">
                  <Link className="breadcrumb__title" to="/feature/">
                    Special Package
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-md-4">
                  <div className="content__feature-item">
                    <div className="content__feature-container">
                      <div
                        className="content__feature-img"
                        style={{ backgroundImage: "url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)" }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = "/"
                      ></Link>
                      </div>
                    </div>
                      <div className="row p-5">
                    <div className="col-md-10">
                    <div style={{fontSize: '1.6rem'}}>
                      <a style={{textDecoration: 'none',color: '#000', fontWeight:'bold' }} href="/">GATEWAY PACKAGE (3 DAYS - 2 NIGHTS)</a>
                    </div>
                    <div  style={{color: '#000', fontWeight:'bold', marginTop: 10 }}>
                    Destinations: <span style={{fontWeight:'lighter'}}>GATEWAY PACKAGE (3 DAYS - 2 NIGHTS)</span>
                    </div>
                      </div>
                      <div className="col-md-2">
                        <div style={{fontSize: '1.4rem'}}>From</div>
                        <div style={{fontSize: '1.4rem'}}>$</div>
                      </div>

                    </div>
                    <div className="d-flex justify-content-center align-items-center read-more-2">
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View Tour
                           </span>
                           </Link>
                      </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content__feature-item">
                    <div className="content__feature-container">
                      <div
                        className="content__feature-img"
                        style={{ backgroundImage: "url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)" }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = "/"
                      ></Link>
                      </div>
                    </div>
                      <div className="row p-5">
                    <div className="col-md-10">
                    <div style={{fontSize: '1.6rem'}}>
                      <a style={{textDecoration: 'none',color: '#000', fontWeight:'bold' }} href="/">GATEWAY PACKAGE (3 DAYS - 2 NIGHTS)</a>
                    </div>
                    <div  style={{color: '#000', fontWeight:'bold', marginTop: 10 }}>
                    Destinations: <span style={{fontWeight:'lighter'}}>GATEWAY PACKAGE (3 DAYS - 2 NIGHTS)</span>
                    </div>
                      </div>
                      <div className="col-md-2">
                        <div style={{fontSize: '1.4rem'}}>From</div>
                        <div style={{fontSize: '1.4rem'}}>$</div>
                      </div>

                    </div>
                    <div className="d-flex justify-content-center align-items-center read-more-2">
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View Tour
                           </span>
                           </Link>
                      </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content__feature-item">
                    <div className="content__feature-container">
                      <div
                        className="content__feature-img"
                        style={{ backgroundImage: "url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)" }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = "/"
                      ></Link>
                      </div>
                    </div>
                      <div className="row p-5">
                    <div className="col-md-10">
                    <div style={{fontSize: '1.6rem'}}>
                      <a style={{textDecoration: 'none',color: '#000', fontWeight:'bold' }} href="/">GATEWAY PACKAGE (3 DAYS - 2 NIGHTS)</a>
                    </div>
                    <div  style={{color: '#000', fontWeight:'bold', marginTop: 10 }}>
                    Destinations: <span style={{fontWeight:'lighter'}}>GATEWAY PACKAGE (3 DAYS - 2 NIGHTS)</span>
                    </div>
                      </div>
                      <div className="col-md-2">
                        <div style={{fontSize: '1.4rem'}}>From</div>
                        <div style={{fontSize: '1.4rem'}}>$</div>
                      </div>

                    </div>
                    <div className="d-flex justify-content-center align-items-center read-more-2">
                            <Link style={{textDecoration: 'none',padding: 10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            View Tour
                           </span>
                           </Link>
                      </div>
                  </div>
                </div>
                
            </div>
          </div>
        </div>
  );
}
