import React from "react";
import {useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Flatpickr from "react-flatpickr";
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import ScrollToTop from "../../units/ScrollToTop";
import promotion from "../../image/promotion.jpg"
import Booking from "../Units/Booking";
import BookingMobile from "../Units/BookingMobile";
export default function Reservation() {
  const { t, i18n} = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state;

  const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
	const inputRef4 = useRef(null);
	const inputRef5 = useRef(null);
  const a = "Reservation"
  const b = "Ho Guom Hotel"
  const c = a + " | "+ b
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('')
  const [country, setCountry] = useState('Vietnam')
  const [startTime, setStartTime] = useState('')
  const [startDate, setStartDate] = useState('10/02/2024')
  const [endDate, setEndDate] = useState('10/03/2024')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('');
  const [specialRequest, setSpecialRequest] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [countries, setCountries] = useState([]);
  console.log("🚀 ~ Reservation ~ countries:", countries)
  useEffect(() => {
    // Fetch country data from the REST Countries API
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        let data = await response.json();
        data.sort((a, b) => a.name.common.localeCompare(b.name.common));
        setCountries(data);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountries();
  }, []);
  const handleKeyDown = (e, ref) => {
		const input = ref.current;    
		const { selectionStart, selectionEnd } = input;
	
		if (e.key === 'ArrowLeft') {
		  e.preventDefault();
		  // Move cursor left
		  if (selectionStart > 0) {
			input.setSelectionRange(selectionStart - 1, selectionStart - 1);
		  }
		} else if (e.key === 'ArrowRight') {
		  e.preventDefault();
		  // Move cursor right
		  if (selectionEnd < input.value.length) {
			input.setSelectionRange(selectionStart + 1, selectionStart + 1);
		  }
		}
	  };

  const [errors, setErrors] = useState({})
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validate = () => { 
    let errors = {}
    let isVaLid = true
  
    if (!firstName) {
      errors.guestName = 'required';
      isVaLid = false
    } 
    if (!lastName) {
      errors.guestName = 'required';
      isVaLid = false
    } 
    if (!email) {
      errors.email = 'required';
      isVaLid = false
  
    } 
    else if (!validateEmail(email)) {
      errors.email = 'Invalid email format';
      isVaLid = false
  
    }
    if (!phone) {
      errors.phone = 'required';
      isVaLid = false
  
    } 
    if (!phone) {
      errors.phone = 'required';
      isVaLid = false
  
    } 
    if (!startTime) {
      errors.startTime = 'required';
      isVaLid = false
  
    }
    setErrors(errors);
    return isVaLid
  }
  const handleSubmit = async(e) => {
    const dataObject = {
      firstName,
      lastName,
      country,
      phone,
      startTime,
      email,
      address,
      specialRequest,
      language,
      deviceType
    }
    // console.log(dataObject);
    e.preventDefault()
    if(validate()) {
      const token = "73344833-5b52-4403-9255-695907647688"
    // const source = await sendMassageRequest(dataObject, token)
    navigate (`/${language}/`)
    }else{
      alert(`Please ensure that all required fields are completed`)
    }
  }
  const handleStartTimeChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      const selectedDate = new Date(selectedDates[0]);
      const timeString = selectedDate.toTimeString().split(" ")[0];
      setStartTime(timeString);
    } else {
      setStartTime(null);
    }
  };
  return (
    <>
    <ScrollToTop x={0} y={1200} />
    <HelmetLayout title={c}/>
    <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Reservation</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
    <BookingMobile />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house" />
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title" to ={`/${language}/contact/`}>
                  Reservation
                </Link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
        <form onSubmit={handleSubmit} style={{border: '1px solid #c5c5c5', padding: 20}}>
              <h2 style={{color: '#000071', fontWeight: 'bold'}}>Guest Information</h2>
              <h4>Complete the reservation with your personal information . Finalize your stay with the best rate as there is no middleman.</h4>
              <div className="row pl-3 pr-3">
                <div className="col-md-6">
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>First name: <span className="required__note">*</span></div>
                <input
                 ref={(inputRef1)}
                 onKeyDown={(e) => handleKeyDown(e, inputRef1)}
                  placeholder={t("service_massage.guest_name")}
                  type="text"
                  className={errors.firstName ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    errors.guestName = ''
                  }}
                />
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Email: <span className="required__note">*</span></div>
                <input
                   ref={(inputRef3)}
                   onKeyDown={(e) => handleKeyDown(e, inputRef3)}
                  type="text"
                  className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={email}
                  placeholder={t("service_massage.email")}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    errors.email = ''
                  }}
                />
                 <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Country: <span className="required__note">*</span></div>
                <select
                  value={country}
                  className={errors.option ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                {countries.map((country) => (
                    <option key={country.cca3} value={country.name.common}>
                    {country.name.common}
                    </option>
                  ))}
                </select>
                </div>
              <div className="col-md-6">
              
              <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Phone Number: <span className="required__note">*</span></div>
                 <input
                 ref={(inputRef2)}
                 onKeyDown={(e) => handleKeyDown(e, inputRef2)}
                  type="text"
                  className={errors.phone ? 'form__content col-md-12 validate_failed' : 'form__content col-md-12'}
                  value={phone}
                  placeholder={t("service_massage.phone_number")}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setPhone(e.target.value)
                    errors.phone = ''
                  }
                  }
                />
                
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Email: *</div>
                <input
                   ref={(inputRef3)}
                   onKeyDown={(e) => handleKeyDown(e, inputRef3)}
                  type="text"
                  className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={email}
                  placeholder={t("service_massage.email")}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    errors.email = ''
                  }}
                />
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Arrival Time: (Optional)</div>
                <Flatpickr
                  value={startTime}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    maxTime: "21:30",
                    time_24hr: true,
                  }}
                  placeholder={t("service_massage.time")}
                  onChange={handleStartTimeChange}
                  className={errors.startTime ? 'col-md-12 form__content webkit-appearance mr-0 validate_failed' : 'col-md-12 form__content webkit-appearance mr-0'}
                />
              </div>
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Any personal request ? (Optional)</div>
                <textarea
                 ref={(inputRef4)}
                 onKeyDown={(e) => handleKeyDown(e, inputRef4)}
                  className="text-note"
                  style={{borderColor: '#c5c5c5'}}
                  cols="40"
                  rows="6"
                  placeholder="Message"
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center">
              <div style={{borderTop:'2px solid #c5c5c5', width: '60%', margin: 20}}></div>
              </div>
              <div className="row">
              <h2 style={{color: '#000071', fontWeight: 'bold'}}>Payment Method</h2>
              <Tabs className="container p-0">
        <TabPanel>
          <Tabs
            selectedTabClassName="service__active"
            className="col-md-12 p-0"
          >
            <TabList className="service__list">
                <Tab className="service">Pay at Hotel</Tab>
                {/* <Tab className="service">PRICE</Tab>
                <Tab className="service">GALLERY</Tab>
                <Tab className="service">BOOKING NOW</Tab> */}
            </TabList>
            <TabPanel>
              <div className="service__content">
                <div className="container">
                  <div className="d-flex justify-content-start">
                    <div style={{fontSize: "1.4rem"}}>
                      <div style={{color: '#000071'}}>Pay for this reservation at the hotel.</div>
                      <div>
                      <br/>Please pay for this reservation at.
                      <br/>39 Thợ Nhuộm, Hoàn Kiếm, Hà Nội, Việt Nam, Hà Nội, Việt Nam</div>
                    </div>
                  </div>
                </div>
              </div>
              </TabPanel>
            {/* 
            <TabPanel>
              <div className="service__content">
                <div className="container">
                  <div className="row">
                    <div style={{fontSize:'1.4rem'}}>
                   <br/> Price From US$ 315 +

                   <br/> <br/>  PRICE IN USD: Please contact us for special offer
                   <br/>  Tel: (+84).379309868 - Whatsapp/ Zalo are available.
                   <br/>  Email: info@acoustichotel.com
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("room_dn.location")}
                    <img
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                      className="style-line"
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <p style={{ fontSize: "1.4rem", paddingTop: "10px", textAlign:"justify" }}>
                            {t("room_dn.locationContent")}
                          </p>
                          <div className="hotel__info">
                            <h1>{t("branch.dn")}</h1>
                            <p>
                              <i className="purple fa-solid fa-location-dot mr-2" />
                              {t("room_dn.address")}
                            </p>
                            <p>
                              <i className="purple fa fa-phone mr-2" />
                              {t("room_dn.tel")} : <a className="ml-1 purple" href={`tel:${t("room_dn.phone")}`}>
                              {t("room_dn.phone")}
                              </a>
                            </p>
                            <p>
                              <i className="purple fa fa-envelope mr-2" />
                              {t("room_dn.emailTitle")} : {" "}
                              <a className="ml-1 purple" href={`mailto:${t("room_dn.email")}`}>
                            {t("room_dn.email")}
                             </a>
                            </p>
                          </div>
                        </div>
                        <div className="room__container">
                          <div className="gg-map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.607453222497!2d108.21247711425566!3d16.08584744326651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142181558c6ded1%3A0xd9b07e55bf351cab!2sAzumaya+Hotel+Da+Nang!5e0!3m2!1svi!2s!4v1480501419661"
                              style={{ border: 0 }}
                              allowFullScreen
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel> */}
          </Tabs>
        </TabPanel>
      </Tabs>
              </div>
                <div className="row justify-content-center">
                  <button
                    id="send"
                    class="button-57 send-btn col-2"
                    type="submit"
                  >
                    <span class="text" style={{ color: "#fff" }}>
                      {t('reservation.send')}
                    </span>
                    <span className="d-flex align-item-center">
                      <i class="fa-sharp fa-regular fa-paper-plane" style={{ fontSize: "1.8rem", lineHeight: "2.8rem", color: '#000071' }}></i>
                    </span>
                  </button>
                </div>
          </form>
          </div>
          <div className="col-md-4">
            <div style={{border: '1px solid #c5c5c5'}}>
              <div className="center" style={{lineHeight: '3.8rem', fontSize: '1.8rem', color:'#fff', backgroundColor:'#000071'}}>YOUR BOOKING SUMMARY</div>
              <div className="row m-0 p-0">
                <div className="col-md-6 pr-3 pl-3 mt-3">
                  <div style={{fontSize: '1.4rem'}}>Check In</div>
                  <div style={{fontSize: '1.8rem', color:'#000071'}}>{startDate}</div>
                </div>
                <div className="col-md-6 mt-3 ">
                  <div style={{fontSize: '1.4rem'}}>Check Out</div>
                  <div style={{fontSize: '1.8rem', color: '#00071'}}>{endDate}</div>
                </div>
                <div style={{fontSize: '1.4rem', marginTop: 10}}>Number of night(s) stay: <span>1</span></div>
                <div style={{fontSize: '1.4rem'}}>Number of room(s): <span>1</span></div>
                <div className="d-flex justify-content-center mt-5">
                      <input
                      ref={(inputRef5)}
                      onKeyDown={(e) => handleKeyDown(e, inputRef5)}
                      placeholder="Input Code..."
                      type="text"
                      className='form__content mr-0'
                      value={discountCode}
                      onChange={(e) => {
                        setDiscountCode(e.target.value);
                      }}
                      />
                      <button style={{height: 42}}>REDEEM CODE</button>
                </div>
                <div style={{fontSize: '1.4rem'}}>DELUXE  (x1)</div>
                <div style={{fontSize: '1.4rem'}}>BE NO BF</div>
                <div style={{fontSize: '1.4rem'}}>2 Adult(s) , 1 Child(s)</div>
                <div style={{fontSize: '1.4rem'}}>$66.66</div>
              </div>
                <div style={{borderBottom: '1px solid #c5c5c5'}}></div>
                <div className="d-flex justify-content-between pr-3 pl-3">
                  <div style={{fontSize: '1.4rem'}}>ROOM TOTAL</div>
                  <div style={{fontSize: '1.4rem'}}>$66.66</div>
                </div>
                <div className="d-flex justify-content-between pr-3 pl-3">
                  <div style={{fontSize: '1.4rem'}}>GRAND TOTAL</div>
                  <div style={{fontSize: '1.4rem'}}>$66.66</div>
                </div>
                <div className="right pr-3 pl-3" style={{color: 'red', fontStyle:'italic'}}>(*) Service fee included</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
