import { Link, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import Cookies from "js-cookie";
import Avatar from 'react-avatar';
import { UserContext } from "../../userProvider";
import profilePicture from "../../image/profile-picture.png"
import logo from "../../image/hanoilogo.png"
// import Collapsible from "react-collapsible";
import Collapsible from "../../Collapsible";
function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let language = i18n.language || 'en'
  const { userImage, logout } = useContext(UserContext);
  const [userEmail, setUserEmail] = useState('')
  const [userData, setUserData] = useState()

  const changeLanguage = (language) => {
  i18n.changeLanguage(language, () => {
    Cookies.set('selectedLanguage', language, { expires: 365 });
    const currentPath = location.pathname;
    const newPath = currentPath.replace(/^\/(en|ja|vi|ko)/, `/${language}`);
    if (currentPath !== newPath) {
      navigate(newPath, { replace: true });
    }
  });
};
  useEffect(() => {
    const savedLang = Cookies.get('selectedLanguage');
    if(savedLang && i18n.language !== savedLang){
      i18n.changeLanguage(savedLang)
      }
  })
  const feature = t("feature.feature_item", { returnObjects: true });
  const hcm = t("hcm-branch.branch", {returnObjects:true})
  const hn = t("hn-branch.branch", {returnObjects:true})
  const dn = t("dn-branch.branch", {returnObjects:true})
  const hp = t("hp-branch.branch", {returnObjects:true})
  const service = t("service.service_name", {returnObjects:true})
  const [isOpen, setIsOpen] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const isProduct = process.env.NODE_ENV === 'production'
    useEffect(() => {
      const handleScroll = () => {
        const position = window.scrollY;
        if (position > 100) {
          setBackgroundColor('#fff'); 
        } else {
          setBackgroundColor('transparent'); 
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  const toggleHeader = () => {
      setIsOpen(!isOpen)
  }
  useEffect(()=>{
    if(isOpen) {
      document.body.style.position= "fixed";
    }else{
      document.body.style.position = "";
    }
  })
;
  const HeaderMobile = () => {
    return(
    <ul className={`header__mobile-navbar-list ${isOpen ? 'open' :''}`}>
          <button
            onClick={()=> setIsOpen(false)}
            className="header__mobile-navbar-closed"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <li className="header__mobile-navbar-item">
            <Link
              onClick={()=> setIsOpen(false)}
              Link
              to={`/${language}/`}
              className="header__mobile-navbar-link mr-2"
            >
              <i class="fa-solid fa-house purple"></i> {t("header.home")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              onClick={()=> setIsOpen(false)}
              Link
              to={`/${language}/news/`}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-newspaper purple mr-2"></i> {t("header.news")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
          <Collapsible className='mt-3' title={t('header.vietnam')} icon={"https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/vn.svg"} width={17} height={12}>
          <ul>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hn/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-hotel purple"></i> {t("header.hn")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hcm/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-hotel purple"></i> {t("header.hcm")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-dn/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-hotel purple"></i> {t("header.dn")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item" style={{borderBottom: 'none'}}>
            <Link
              to={`/${language}/hotel-hp/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-hotel purple"></i> {t("header.hp")}
            </Link>
          </li>
          </ul>
           </Collapsible>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to="http://azumayacambodia.com"
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
             <img src ="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kh.svg" style={{width:17, height: 12}}/> {t("header.cambodia")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/reservation/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-bell-concierge purple mr-2"></i> {t("header.reservation")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/feature/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-star purple mr-2"></i> {t("header.feature")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
         <Collapsible className='mt-3' title={t('header.service')} icon={"https://cdn-icons-png.flaticon.com/512/7096/7096487.png"} marginRight={5} width={17} height={17} > 
              <ul>
              <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/breakfast/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link d-flex align-items-center"
            >
             <img src="https://cdn-icons-png.flaticon.com/512/3911/3911444.png" style={{width:20, height: 15, marginRight: 5}}/> {t("header.breakfast")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/rotenburo/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link d-flex align-items-center"
            >
              <img src="https://cdn-icons-png.flaticon.com/512/7802/7802322.png" style={{width:20, height: 15, marginRight: 5}}/> {t("header.roten")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item" style={{borderBottom:'none'}}> 
            <Link
              to={`/${language}/massage/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link d-flex align-items-center"
            >
             <img src="https://cdn-icons-png.flaticon.com/512/8119/8119611.png" style={{width:20, height: 20, marginRight: 5}}/>  {t("header.massage")}
            </Link>
          </li>
              </ul>
          </Collapsible>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/contract/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
             <i class="fa-solid fa-handshake purple mr-2"></i> {t("header.contract")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/q&a`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i class="fa-solid fa-clipboard-check purple mr-4" /> {t("header.policies")}
            </Link>
          </li>
        </ul>
    )
  }
  return (
    <>
      <div className='top-header'>
                <div className='container-fluid'>
                    <div className='top-header-container align-items-center justify-content-center'>
                        <div className='col-md-9'>
                        <p className="top-header-text">{t('header.top-header')}</p>
                     
                        </div>
                        <div className='btn-lang col-md-3'>
                              <button className ='btn-en mr-3' onClick={()=>{changeLanguage('en')}}></button>                
                              <button className ='btn-ja mr-3' onClick={()=>{changeLanguage('ja')}}></button>  
                              <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button> 
                              <button className ='btn-kor mr-3' onClick={()=>{changeLanguage('ko')}}></button> 
                          </div>
                        
                    </div>
                </div>
            </div>
      <div className="header_container">
      <div className="header-2">
        <div className="header__PC-2">
          <div className="header__logo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to="/" >
                    <img
                      style={{width:"60px"}}
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__mobile-logo">
          <Link to="/">
            <img
               src={logo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="btn__header-mobile">
          <button
           onClick={toggleHeader}
           className="header__mobile">
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <HeaderMobile />
      </div>
      </div>
     <div className="is-sticky">
     <div className="container">
      <div className="header" style={{backgroundColor: backgroundColor}}>
        <Tabs
        className="header__PC">
             <TabList className="header__navbar-list mb-0 justify-content-start">
             <Tab className="header__navbar-item">
             <div className="header__logo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to={`/${language}/`}>
                    <img
                       src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
                </Tab>
              </TabList>      
                  <TabList className="header__navbar-list mb-0">
                  <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/`}>
                        {t("header.home")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/about/`}>
                        {t("header.about")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width:130}} to={`/${language}/room/`}>
                        {t("header.room")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/service/`}>
                        {t("header.service")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/tour/`}>
                        {t("header.tour")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                          <li className = "header__navbar-sub-link" >
                          <Link 
                          to ={`/${language}/tour-detail/`}>Tour 1
                          </Link>
                            </li>
                            <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Tour 2
                          </Link>
                            </li>
                            <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Tour 3
                          </Link>
                            </li>
                            <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Tour 4
                          </Link>
                            </li>
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/promotion/`}>
                        {t("header.promotion")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2"  style={{width: 150}}  to={`/${language}/special-package/`}>
                        {t("header.package")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                      <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Special Package 1
                          </Link>
                            </li>
                            <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Special Package 2
                          </Link>
                            </li>
                            <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Special Package 3
                          </Link>
                            </li>
                            <li className = "header__navbar-sub-link" >
                          <Link 
                          to ="/">Special Package 4
                          </Link>
                            </li>
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/gallery`}>
                      {t("header.gallery")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/contact/`}>
                        {t("header.contact")}
                      </Link>
                    </Tab>
                  </TabList>
                  </Tabs>
            </div>
          </div>
     </div>
    </>
  );
}
export default Header;