import Cookie from 'js-cookie';
import { getCookies } from './getCookies';
import { useState, useEffect } from 'react';
export default function CookiePolicy() {
  const [cookies, setCookies] = useState({});

    useEffect(() => {
      setCookies(getCookies());
    }, []);
  const CookieDisplay = () => {
    return (
      <div className="cookie-display">
        <table>
        {Object.entries(cookies).map(([name, { value, expires }]) => (
          <tr>
            <td>{name}</td> 
            <td>{value}</td>
            <td>Expires:  {expires ? expires : 'Session'}</td>
          </tr>
        ))}
        </table>
      </div>
    );
  };

    return (
        <>
        <div className="reservation__content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Privacy Policies</h1>
            </div>
          </div>
        </div>
      </div>
        <div className="container" style={{marginTop: 50}}>
            <div className="row">
              <div className="policy-container">
              <h1>
        <strong>
          Cookie Policy of <a style={{fontSize: 'unset'}} href="http://www.azumayavietnam.com/">Azumaya Vietnam website</a>
        </strong>
      </h1>
      <p>Last updated: 15/07/2024</p>
      <p>
        We are Azumaya Hotel. This cookie policy outlines how we collect, use, and manage cookies, and we outline your rights and choices regarding cookie usage.
      </p>
      <p>You can contact us at reservationhanoi6@azumayavietnam.com.</p>
      <hr />
      <h2>Table of contents</h2>
      <div className="d-flex flex-column">
        <a href ='#introduction'>Introduction</a>
        <a href ='#contact'>Contact information</a>
        <a href ='#activity'>Activities strictly necessary for the operation of this website and delivery of the service</a>
        <a href ='#consent'>How to manage preferences and provide or withdraw consent</a>
        <a href ='#definition'>Definitions and legal references</a>
      </div>
      <hr />
      <h2 id ='introduction' className="offset-anchor">
        <strong>Introduction</strong>
      </h2>
      <p>
        <strong>What is this policy about?</strong>
        <br />
        This document tells you about tracking technologies (“trackers”) that help this website achieve the purposes outlined in this document.
      </p>
      <p>
        <strong>What are trackers?</strong>
        <br />
        For simplicity, all such technologies are defined as "trackers" within this document – unless there is a reason to differentiate. For example, while cookies can be used on both web and mobile browsers, it would be inaccurate to talk about cookies in the context of mobile apps as they are a browser-based tracker. For this reason, within this document, the term cookies is only used where it is specifically meant to indicate that particular type of tracker.
      </p>
      <p>
        Some of the purposes for which trackers are used may also require your consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.
      </p>
      <p>
        This website uses trackers managed directly by us (so-called “first-party” trackers) and trackers that enable services provided by a third-party (so-called “third-party” trackers). Unless otherwise specified within this document, third-party providers may access the trackers managed by them.
      </p>
      <p>
        The validity and expiration periods of cookies and other similar trackers may vary depending on the lifetime set by us or the relevant provider. Some of them expire upon termination of your browsing session. In addition to what’s specified in the descriptions within each of the categories below, you may find more precise and updated information regarding lifetime specification as well as any other relevant information — such as the presence of other trackers— in the linked privacy policies of the respective third-party providers or by contacting us.
      </p>
      <p>
        This document was generated with the use of the{' '}
        <a href="https://www.iubenda.com/en/help/54673-cookie-policy-template">cookie policy template</a>.
      </p>
      <hr />
      <h2 id ="contact" className="offset-anchor">
        <strong>Contact Information</strong>
      </h2>
      <p>
        Since the use of third-party trackers through this website cannot be fully controlled by us, any specific references to third-party trackers are to be considered indicative. In order to obtain complete information, you are kindly requested to consult the privacy policies of the respective third-party services listed in this document.
      </p>
      <p>
        Given the objective complexity surrounding tracking technologies, you are encouraged to contact us should you wish to receive any further information on the use of such technologies by this website.
      </p>
      <p>
        <strong>Address:</strong>
        <br />
        38 Hai Ba Trung St., Trang TIen Ward., Hoan Kiem Dist.,
        <br />
        Ha Noi, 100000
        <br />
        Viet Nam
      </p>
      <p>
        <strong>Email:</strong> reservationhanoi6@azumayavietnam.com
      </p>
      <p>
        <strong>Phone:</strong> +84.24 3734 9730
      </p>
      <hr />
      <h2 id="activity" className="offset-anchor">
        <strong>Activities strictly necessary for the operation of this website and delivery of the service</strong>
      </h2>
      <p>This website uses so-called “technical” cookies and other similar trackers to carry out activities that are strictly necessary for the operation or delivery of the service.</p>
      <h4>
        <strong>Other activities involving the use of trackers</strong>
      </h4>
      <h5>
        <strong>Experience</strong>
      </h5>
      <p>
        This website uses trackers to improve the quality of the user experience and enable interactions with external content, networks and platforms.
      </p>
      <p>
        <strong>Displaying content from external platforms</strong>
        <br />
        This type of service allows you to view content hosted on external platforms directly from the pages of this website and interact with them. Such services are often referred to as widgets, which are small elements placed on a website or app. They provide specific information or perform a particular function and often allow for user interaction. This type of service might still collect web traffic data for the pages where the service is installed, even when you do not use it.
      </p>
      <p>
        <strong>Google Fonts (Google LLC)</strong>
        <br />
        Google Fonts is a typeface visualization service provided by Google LLC that allows this website to incorporate content of this kind on its pages.
      </p>
      <p>
        To understand Google's use of data, consult Google's{' '}
        <a href="https://policies.google.com/technologies/partner-sites">partner policy</a> and their{' '}
        <a href="https://business.safety.google/privacy/">Business data page</a>.
      </p>
      <p>
        <strong>Personal data processed:</strong> Trackers; Usage data
        <br />
        <strong>Place of processing:</strong> United States
        <br />
        <strong>Privacy policy:</strong>{' '}
        <a href="https://business.safety.google/privacy/">https://business.safety.google/privacy/</a>
        <br />
        <strong>Opt-out link:</strong> <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
      </p>
      <h4>
        <strong>Measurement</strong>
      </h4>
      <p>
        This website uses trackers to measure traffic and analyze user behavior to improve the service.
      </p>
      <p>
        <strong>Analytics</strong>
        <br />
        The services contained in this section enable us to monitor and analyze web traffic and can be used to keep track of user behavior.
      </p>
      <p>
        <strong>Google Analytics (Universal Analytics) (Google LLC)</strong>
        <br />
        Google Analytics (Universal Analytics) is a web analysis service provided by Google LLC (“Google”). Google utilizes the Data collected to track and examine the use of this website, to prepare reports on its activities and share them with other Google services. Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
      </p>
      <p>
        To understand Google's use of data, consult Google's{' '}
        <a href="https://policies.google.com/technologies/partner-sites">partner policy</a> and their{' '}
        <a href="https://business.safety.google/privacy/">Business data page</a>.
      </p>
      <p>
        <strong>Personal data processed:</strong> Trackers; Usage data
        <br />
        <strong>Place of processing:</strong> United States
        <br />
        <strong>Privacy policy:</strong>{' '}
        <a href="https://business.safety.google/privacy/">https://business.safety.google/privacy/</a>
        <br />
        <strong>Opt-out link:</strong> <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
      </p>
      <p>
        <strong>Storage duration:</strong>
        <CookieDisplay />
        {/* <br />
        AMP_TOKEN: 1 hour
        <br />
        _ga: 2 years
        <br />
        _gac*: 3 months
        <br />
        _gat: 1 minute
        <br />
        _gid: 1 day */}
      </p>
      <hr />
      <h2 id="consent" className="offset-anchor">
        <strong>How to manage preferences and provide or withdraw consent</strong>
      </h2>
      <p>There are various ways to manage tracker related preferences and to provide and withdraw consent, where relevant:</p>
      <p>
        You can manage preferences related to trackers from directly within your own device settings, for example, by preventing the use or storage of trackers.
      </p>
      <p>
        Additionally, whenever the use of trackers is based on consent, you can provide or withdraw such consent by setting your preferences within the cookie notice or by updating such preferences accordingly via the relevant consent-preferences privacy widget, if available.
      </p>
      <p>
        It is also possible, via relevant browser or device features, to delete previously stored trackers, including those used to remember your initial consent preferences.
      </p>
      <p>Other trackers in the browser’s local memory may be cleared by deleting the browsing history.</p>
      <p>
        With regard to any third-party trackers, you can manage your preferences via the related opt-out link (where provided), by using the means indicated in the third party's privacy policy, or by contacting the third party.
      </p>
      <h3>
        <strong>Locating tracker settings</strong>
      </h3>
      <p>
        You can, for example, find information about how to manage cookies in the most commonly used browsers at the following addresses:
      </p>
      <ul>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">Google Chrome</a>
        </li>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/en-za/guide/safari/sfri11471/mac">Apple Safari</a>
        </li>
        <li>
          <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">
            Microsoft Internet Explorer
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">
            Microsoft Edge
          </a>
        </li>
        <li>
          <a href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing">Brave</a>
        </li>
        <li>
          <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>
        </li>
      </ul>
      <p>
        You may also manage certain categories of trackers used on mobile apps by opting out through relevant device settings such as the device advertising settings for mobile devices, or tracking settings in general (you may open the device settings and look for the relevant setting).
      </p>
      <h3>
        <strong>Consequences of denying the use of trackers</strong>
      </h3>
      <p>
        You are free to decide whether or not to allow the use of trackers. However, please note that trackers help this website to provide a better experience and advanced functionalities to you (in line with the purposes outlined in this document). Therefore, if you choose to block the use of trackers, we may be unable to provide related features.
      </p>
      <hr />
      <h2 id="definition" className="offset-anchor">
        <strong>Definitions and legal references</strong>
      </h2>
      <p>
        <strong>Personal data (or data)</strong>
        <br />
        Any information that directly, indirectly, or in connection with other information — including a personal identification number— allows for the identification or identifiability of a natural person (in other words, you).
      </p>
      <p>
        <strong>Usage data</strong>
        <br />
        Usage data is information automatically collected through this website or third-party services, including your IP address, browser type, operating system, time and method of requests, response status, visit duration, page sequence, and device-specific details.
      </p>
      <p>
        <strong>This website</strong>
        <br />
        The means by which your personal data is collected and processed.
      </p>
      <p>
        <strong>Service</strong>
        <br />
        The service provided by this website as described in the Terms of Service and on this site.
      </p>
      <p>
        <strong>European Union (or EU)</strong>
        <br />
        Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
      </p>
      <p>
        <strong>Cookie</strong>
        <br />
        Cookies are trackers consisting of small sets of data stored in your browser.
      </p>
      <p>
        <strong>Tracker</strong>
        <br />
        Tracker indicates any technology - e.g. cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of you, for example by accessing or storing information on your device.
      </p>
      <p>
        <strong>Legal information</strong>
        <br />
        This privacy statement has been prepared based on provisions of multiple legislations. This privacy policy relates solely to this website, if not stated otherwise within this document.
      </p>
              </div>
            </div>
        </div>
        </>
    )
}