import React, { useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import { getCookies } from './getCookies';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const COOKIE_CONSENT_NAME = 'cookieConsent';
const COOKIE_DECLINE_NAME = 'cookieDecline';

const CookieDisplay = () => {
    const cookies = getCookies();
  
    return (
      <div className="cookie-display">
        <table>
        {Object.entries(cookies).map(([name, value]) => (
            <tr>
                <td>{name}</td>
                <td>{value}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  };

const CookieConsent = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en'
  useEffect(() => {
    const consent = Cookie.get(COOKIE_CONSENT_NAME);
    const decline = Cookie.get(COOKIE_DECLINE_NAME);
    if (consent) {
      setIsConsentGiven(true);
    }
    if (decline) {
      setIsDeclined(true);
    }
  }, []);

  const handleConsent = () => {
    Cookie.set(COOKIE_CONSENT_NAME, 'true', { expires: 365 }); 
    setIsConsentGiven(true);
  };
  const handleDecline = () => {
    Cookie.set(COOKIE_DECLINE_NAME, 'true', { expires: 365 }); 
    setIsDeclined(true);
    setIsConsentGiven(false);
  };
  if (isConsentGiven || isDeclined) return null;

  return (
    <></>
    // <div className="cookie-consent">
    //   <button type="button" class="btn-close btn-close-mod" onClick={handleDecline} aria-label="Close"></button>
    //   <h2>We use cookies</h2>
    //   <p className='mb-4 '>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
    //   <button className='button-17 mr-3' onClick={handleConsent}>Accept</button>
    //   {/* <button className='button-17 mr-3'  onClick={handleDecline}>Decline</button> */}
    //   <button className='button-17' onClick={() => navigate(`/${language}/cookie-policy`)}>Cookies Policies</button>
    // </div>
  );
};

export default CookieConsent;
