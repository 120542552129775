// import "flatpickr/dist/themes/airbnb.css";
import Booking from "../Units/Booking";
import BookingMobile from "../Units/BookingMobile";
import { useTranslation } from "react-i18next";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import {Form, Link} from "react-router-dom"
import {format, parse} from "date-fns"
import { useState, useEffect } from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';  
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import { useLocation } from "react-router-dom";
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../../image/RTBRHBT1.jpeg'
import image2 from '../../image/RTBRKM2.jpeg'
import image3 from '../../image/RTBRLL.jpg'
export default function Home({deviceType, browser}) {
  const { t, i18n } = useTranslation();
  const location = useLocation()
  const language = i18n.language || 'en'
  const caption = t("caption", {returnObjects: true})
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: deviceType === 'pc' ? 3 : 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>
  };
  const settings2 = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>
  };
const AutoPlaySlider = withAutoplay(AwesomeSlider);
const a = "Home"
const b = "Ho Guom Hotel"
const c = a + " | "+ b

  return (
    <>
    <HelmetLayout title={c}/>
    <ToastContainer 
         position="top-right"
         transition={Flip}
         />
    <div className="homepage">
    <div className="content">
      <div>
    <Booking />
        <AutoPlaySlider
            animation="scaleOutAnimation"
            mobileTouch
            infinite
            play={false}
            interval={5000}
            bullets={false}
            
        >
            {caption.map((item) => (
                <div key={item.id} data-src={item.image}>
                    {/* <Link to={`/${language}${item.link}`}>
                        <p className="carousel_name" title={item.name}>
                            {item.name}
                            <br />
                            {item.caption}
                        </p>
                    </Link> */}
                </div>
            ))}
        </AutoPlaySlider>
      </div>
</div>
        <BookingMobile />
      <>
        <AnimatedOnScroll>
        <div className="container mt-5">
          <div className="row justify-content-center align-item-center ml-5 mr-5">
              <div className="col-md-6 pre-line">
                <div className="promo-title">WELCOME TO M Village Ho Guom Hotel</div>
                <p className="promo-text mt-5">Located in the middle of French Quarter, Ha Noi Old Quarter and governmental area, as well as the famous shopping and entertainment areas, this trendy hotel’s location is ideal for shopping, entertainment, leisure travel and business trip purpose.
                <br/><br/>With 75 modern - well point designed rooms, Acoustic Hotel & Spa has what every discerning traveler is looking for. From its floor to ceiling windows with panoramic views of the sprawling metropolis of Ha Noi to its comfortable ambiance and luxury neo-classical design.
                <br/><br/>The Acoustic Hotel & Spa provides a multitude of drinking and dining options on-site, including Indigo Restaurant, Eclipse Sky Bar – one of the best rooftop bars in Ha Noi, ideal for both relaxed & special dining venue for friends to enjoy some fine wines & cocktails.</p>
                <div className="d-flex justify-content-start">
              <button
              style={{width:'200px'}}
                          class="button-57 call-btn mt-5"
                          role="button"
                        >
                          <Link to = {`/${language}/massage/`}>
                          <span style={{color:'#482979'}} class="text">
                            READ MORE
                          </span>
                          <span><i class="fa-solid fa-circle-chevron-right"></i></span>
                          </Link>
                        </button>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <img fetchpriority="high" style={{width: '100%', height:'100%'}} src="https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg" alt="massage" />
              </div>
          </div>
        </div>
        </AnimatedOnScroll>
        <AnimatedOnScroll>
          <div className="container">
            <div className="row">
            <div className="slider-container">
              <Slider {...settings}>
                <div className="d-flex justify-content-center">
                  <div style={{border: '2px solid #ccc', width: '80%',  borderRadius: 10}}>
                    <img src="https://shingolf.vn/image/hoguomhotel/room/Suite/picture1.png" style={{height: 200, width:'100%',  borderTopLeftRadius: 8, borderTopRightRadius: 8}}/>
                    <div style={{fontSize: '3rem', padding: 20}}>SUITE</div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="col-md-6 d-flex justify-content-center align-items-center" style={{borderTop: '2px solid #ccc', borderRight: '2px solid #ccc', height: 50}}>
                        <div style={{fontSize: '1.6rem'}}>From 100$</div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-center align-items-center read-more" style={{borderTop: '2px solid #ccc', height: 50, borderBottomRightRadius: 8}}>
                            <Link style={{textDecoration: 'none'}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.6rem'}}>
                             Read More <i class="fa-solid fa-circle-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div style={{border: '2px solid #ccc', width: '80%',  borderRadius: 10}}>
                    <img src="https://shingolf.vn/image/hoguomhotel/room/Deluxe%20city%20view%20room/picture1.png" style={{height: 200, width:'100%',  borderTopLeftRadius: 8, borderTopRightRadius: 8}}/>
                    <div style={{fontSize: '3rem', padding: 20}}>DELUXE</div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="col-md-6 d-flex justify-content-center align-items-center" style={{borderTop: '2px solid #ccc', borderRight: '2px solid #ccc', height: 50}}>
                        <div style={{fontSize: '1.6rem'}}>From 100$</div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-center align-items-center read-more" style={{borderTop: '2px solid #ccc', height: 50, borderBottomRightRadius: 8}}>
                            <Link style={{textDecoration: 'none'}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.6rem'}}>
                             Read More <i class="fa-solid fa-circle-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div style={{border: '2px solid #ccc', width: '80%',  borderRadius: 10}}>
                    <img src="https://shingolf.vn/image/hoguomhotel/room/Deluxe%20twin%20city%20view/picture1.png" style={{height: 200, width:'100%',  borderTopLeftRadius: 8, borderTopRightRadius: 8}}/>
                    <div style={{fontSize: '3rem', padding: 20}}>TWIN</div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="col-md-6 d-flex justify-content-center align-items-center" style={{borderTop: '2px solid #ccc', borderRight: '2px solid #ccc', height: 50}}>
                        <div style={{fontSize: '1.6rem'}}>From 200$</div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-center align-items-center read-more" style={{borderTop: '2px solid #ccc', height: 50, borderBottomRightRadius: 8}}>
                            <Link style={{textDecoration: 'none'}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.6rem'}}>
                             Read More <i class="fa-solid fa-circle-chevron-right"></i>
                           </span>
                           </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
              </div>
            </div>
          </div>
        </AnimatedOnScroll>
      <AnimatedOnScroll>
      <div className="container mt-5">
          <div className="row ml-5 mr-5">
              <div className="col-12 col-md-6 ">
            <div className="ml-3" style={{fontSize: '3rem', fontWeight: 'bold'}}>Special Package</div>
            <div className="slider-container">
              <Slider {...settings2}>
                  <div style={{width: '80%',  borderRadius: 10}}>
                  <div className="content__feature-item">
                  <div className="content__feature-container-2">
                  <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 400, width: '100%' }}
                      ></div>
                  </div>
                  </div>
                  </div>
                  <div style={{width: '80%',  borderRadius: 10}}>
                  <div className="content__feature-item">
                  <div className="content__feature-container-2">
                  <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 400, width: '100%' }}
                      ></div>
                  </div>
                  </div>
                  </div>
                  <div style={{width: '80%',  borderRadius: 10}}>
                  <div className="content__feature-item">
                  <div className="content__feature-container-2">
                  <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 400, width: '100%' }}
                      ></div>
                  </div>  
                  </div>
                  </div>
                </Slider>
              </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="ml-3" style={{fontSize: '3rem', fontWeight: 'bold'}}>Our Service</div>
                <div className="row pt-0">
                     <div className="col-12 col-md-6 pr-md-3">
                  <div className="content__feature-item">
                    <div className="content__feature-container-2">
                    <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 180, width: '100%' }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = {`/${language}/feature/`}
                      ></Link>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 pl-md-3">
                  <div className="content__feature-item">
                    <div className="content__feature-container-2">
                    <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 180, width: '100%' }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = {`/${language}/feature/`}
                      ></Link>
                      </div>
                    </div>
                    </div>
                    </div>
                     <div className="col-12 col-md-6 pr-md-3">
                  <div className="content__feature-item">
                    <div className="content__feature-container-2">
                    <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 180, width: '100%' }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = {`/${language}/feature/`}
                      ></Link>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 pl-md-3">
                  <div className="content__feature-item">
                    <div className="content__feature-container-2">
                    <div
                        className="content__feature-img"
                        style={{ backgroundImage: `url(https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg)`, height: 180, width: '100%' }}
                      >
                          <Link 
                          className="d-block"
                          style={{height: '100%'}}
                      to = {`/${language}/feature/`}
                      ></Link>
                      </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
              </div>
          </div>
      </AnimatedOnScroll>
      </>
     {/* )}  */}
    </div>
    </>
  );
}

