import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import { useEffect} from 'react';
import HelmetLayout from "../../HelmetLayout/HelmetLayout";
import Booking from "../../../container/Units/Booking";
import BookingMobile from "../../../container/Units/BookingMobile";


export default function RoomDetail() {
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en'
  const featureItem = t("feature.feature_item", { returnObjects: true });
    const {featureID} = useParams()
    useEffect(() => {
      if (featureID !== undefined) {
        const elementToScroll = document.getElementById(`${featureID}`);
        if (elementToScroll) {
          elementToScroll.scrollIntoView({ behavior: 'smooth', block:'center', inline:'nearest' });
        }
        
      }
    }, [featureID]);
    const a = t("header.feature")
    const b = t("header.title2")
    const c = a + " | "+ b
  return (
    <div>
      <HelmetLayout title={c} />
      <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Room Detail</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
    <BookingMobile />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="re__breadcrumb">
              <ul className="breadcrumb__list">
                <li className="breadcrumb__item">
                  <i className="fa-solid fa-house"></i>
                  <Link to="/"></Link>
                </li>
                <li className="breadcrumb__item">/</li>
                <li className="breadcrumb__item">
                  <Link className="breadcrumb__title" to="/feature/">
                    Room Detail
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="feature__type-list">
        <div className="container" >
              <div className="feature__type-item">
              <div className="card mb-3" style={{ border: "none" }}>
                <div className="row g-4">
                  <div className="col-md-4">
                    <img className="img-fluid" src="https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg" alt="" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body" style={{ padding: 0 }}>
                      <div className="d-flex justify-content-between">
                      <h2 style={{color: '#000071', fontSize: '2rem', fontWeight: 'bold'}}>Deluxe Double Room with Balcony</h2>
                      <div style={{fontSize: '1.2rem',width: 130 }}>Information details</div>
                      </div>
                      <p className="card-text"><i class="fa-solid fa-bed"></i> 1 Double bed</p>
                      <div style={{border: '1px solid #c5c5c5', opacity: 0.5}}></div>
                      <div className="d-flex justify-content-between">
                        <div>
                        <div style={{fontSize: '1.6rem'}}>BE NO BF</div>
                        <div style={{fontSize: '1.4rem', marginTop: 20}}><i class="fa-solid fa-user"></i> 2 Adult(s), 1 Child (s)</div>
                        <div style={{color: '#fff', backgroundColor: '#008009', width: 100, textAlign:'center', padding: 5, borderRadius:5, fontSize: '1rem', marginTop: 20}}>Discount 75%</div>
                        <div style={{color: '#fff', backgroundColor: '#0DCAF0', width: 120, textAlign:'center', padding: 5, borderRadius:5, fontSize: '1rem', marginTop: 5}}>Limited-time deal</div>
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                          <div style={{textDecoration:'line-through', fontSize: '1.4rem'}}>₫{Intl.NumberFormat('de-DE').format(6660000)}</div>
                          <div style={{fontSize: '1.8rem', color:'red'}}>₫{Intl.NumberFormat('de-DE').format(6660000)}</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link style={{textDecoration:'none', fontSize: '1.2rem', backgroundColor:'#000071', color:'#fff', padding:10}} to = {`/${language}/reservation/`}>Select</Link>
                        </div>
                      </div>
                      <div style={{border: '1px solid #c5c5c5', marginTop: 10, opacity:0.5}}></div>
                      <div className="d-flex justify-content-between">
                        <div>
                        <div style={{fontSize: '1.6rem'}}>BE INCLD BF</div>
                        <div style={{fontSize: '1.4rem', marginTop: 20}}><i class="fa-solid fa-user"></i> 2 Adult(s), 1 Child (s)</div>
                        <div style={{color: '#000071', fontSize: '1.4rem'}}><i class="fa-solid fa-check"></i> Breakfast Include</div>
                        <div style={{color: '#fff', backgroundColor: '#008009', width: 100, textAlign:'center', padding: 5, borderRadius:5, fontSize: '1rem', marginTop: 20}}>Discount 75%</div>
                        <div style={{color: '#fff', backgroundColor: '#0DCAF0', width: 120, textAlign:'center', padding: 5, borderRadius:5, fontSize: '1rem', marginTop: 5}}>Limited-time deal</div>
                        </div>
                        <div className="d-flex justify-content-center flex-column">
                          <div style={{textDecoration:'line-through', fontSize: '1.4rem'}}>₫{Intl.NumberFormat('de-DE').format(6660000)}</div>
                          <div style={{fontSize: '1.8rem', color:'red'}}>₫{Intl.NumberFormat('de-DE').format(6660000)}</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link style={{textDecoration:'none', fontSize: '1.2rem', backgroundColor:'#000071', color:'#fff', padding:10}} to = {`/${language}/reservation/`}>Select</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
