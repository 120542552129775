import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import Booking from "../Units/Booking";
import promotion from "../../image/promotion.jpg"
import BookingMobile from "../Units/BookingMobile";
export default function Promotion() {

    const {t} = useTranslation()
    const a = t("thankyou_contract.name")
    const b = t("header.title2")
    const c = a + " | "+ b

    return(
      <>
      <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Promotion</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
      <BookingMobile />
      <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to="/">
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title">Promotion</Link>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
        <div className="container">
            <div className="row">
                <div className="col-md-12 pre-line">
                  <div style={{fontSize: '1.6rem',fontWeight:'bold', color:'#000071'}}>PROMOTIONS ARE AVAIABLE AT ACOUSTIC HOTEL & SPA</div>
                    <ul>
                      <li style={{textDecoration:'dot', fontSize:"1.4rem"}}><i class="fa-solid fa-check"></i> At INDIGO RESTAURANT: We serve the Western & Vietnamese foods: 15% Discount</li>
                      <li style={{textDecoration:'dot', fontSize:"1.4rem"}}><i class="fa-solid fa-check"></i> At ECLIPSE SKY BAR: Daily buy 01 get 01 free - Fresh beer.</li>
                      <li style={{textDecoration:'dot', fontSize:"1.4rem"}}><i class="fa-solid fa-check"></i> At ECLIPSE SKY BAR: Mon - Thur get 20% off - Mixed drinks.</li>
                      <li style={{textDecoration:'dot', fontSize:"1.4rem"}}><i class="fa-solid fa-check"></i> At ECLIPSE SKY BAR: Fri - Sun get 30% off - Mixed drinks.</li>
                      <li style={{textDecoration:'dot', fontSize:"1.4rem"}}><i class="fa-solid fa-clock"></i> Valid: From 01st May 2024.</li>
                    </ul>
                    <div className="d-flex justify-content-center">
                    <img src={promotion} />
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}