import React from "react";
import {useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classNames from "classnames";
import HelmetLayout from "../../HelmetLayout/HelmetLayout";
import ScrollToTop from "../../../units/ScrollToTop";
import Booking from "../../../container/Units/Booking";
import BookingMobile from "../../../container/Units/BookingMobile";
export default function TourDetail() {
  const { t, i18n} = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state;

  const handleContinue = (selectedRoom) => {
    const data = {
      ...receivedData,
      startDate,
      endDate,
      selectedCity,
      selectedBranch,
      selectedRoom
    };
    navigate(`/${language}/reservation/`, { state: data });
  };

  const a = "Tour Detail"
  const b = "Ho Guom Hotel"
  const c = a + " | "+ b
  return (
    <>
    <ScrollToTop x={0} y={1200} />
    <HelmetLayout title={c}/>
    <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Tour Detail</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
    <BookingMobile />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house" />
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title" to={`/${language}/tour/`}>
                Tour
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title" to ={`/${language}/tour-detail/`}>
                 Tour Detail
                </Link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2">
              <img src="https://shingolf.vn/image/hoguomhotel/community/lobby1.jpeg" width={230} height={150}/>
          </div>
          <div className="col-12 col-md-8 ml-5">
            <div style={{fontWeight:"bold", fontSize: '1.8rem'}}>Stellar Of The Seas 02 Day/01 Night</div>
            <div style={{fontSize:'1.4rem', marginTop :10}}>Stellar of the Seas cruise welcomes you to experience the new definition of contemporary luxury. We offer not only a luxury cruise service of the highest safety standard but also a completely unique experience with a carefully researched itinerary, attentive service, authentic decor, delicious food... That makes your vacation be a truly unique and unforgettable experience</div>
          </div>
        </div>
      </div>
        <Tabs className="container p-0">
        <TabPanel>
          <Tabs
            selectedTabClassName="service__active"
            className="col-md-12 p-0"
          >
            <TabList className="service__list">
                <Tab className="service">ITINERARY</Tab>
                <Tab className="service">PRICE</Tab>
                <Tab className="service">GALLERY</Tab>
                <Tab className="service">BOOKING NOW</Tab>
            </TabList>
            <TabPanel>
              <div className="service__content">
                <div className="container">
                  <div className="row">
                    <div style={{fontSize: "1.4rem"}}>
                       <br/>Discovery Halong bay – Lan Ha bay 2days/ 1night
                        <br/>(Our itinerary is unique and different, we will take you to the less touristy and enchanted sceneries)
                    
                        <br/> <br/>Day One: Hanoi - Tuan Chau marina - Halong bay – Lunch – Swimming at Lagoon natural area (in Lan Ha Bay) – Sunset party – Cookery demonstration – BBQ Dinner – Onboard night activities.
                    
                        <br/> 09:00 – 09:15 approx
                        <br/> - Pick up at your hotel in Hanoi old quarter
                        <br/>- Start 2,5-hour road journey via 5B highway on ultra-luxurious limousine or MPV Kia Sedona (for the guests use our transfer service)
                    
                        <br/> <br/>11:45 – 12.00 approx
                        <br/>- Arriving Tuan Chau marina, transferred by speedboat to Stellar of the Seas. Welcome aboard the cruise. Refresh with our welcome drink. Meet the captain, crew in the cruise briefing, safety instruction.
                        <br/>- Set sail as lunch is served
                    
                        <br/> 12:30
                        <br/> - Join us for a typical lunch in the luxury dining room with indoor or outdoor dining area.
                        <br/>- Check in your cabin. Time to refresh and unwind
                        <br/> - Continue cruising through Con Vit islet, Thumb islet and Gia Luan harbor where belonged to Cat Ba area.
                        <br/> - Discover lagoon natural area belonged to Lan Ha bay. Drop anchor for Kayaking or swimming or just simply sunbathe on our cruise (private, wild and fresh air).
                        <br/>- Heading back toward the sleeping area
                    
                        <br/>Early evening
                        <br/> Summit on the sundeck for watching the sunset, enjoy Sunset party with complimentary cocktails demonstration. It’s ideal time to make acquaintance with new friends, sharing your traveling experience or simply watching the sunset or just relax in the seasonal swimming pool, or enjoy your time in our golf course or do the exercise at fitness area.
                    
                        <br/> 18:00
                        <br/>Join us for a short class of cooking demonstration. Our chef will guide you how to make famous traditional Vietnamese spring rolls. Here you will understand the culinary culture of the Vietnamese
                    
                        <br/> 19:00
                        <br/> - BBQ dinner is served with fresh food menu outside of swimming pool area.
                        <br/> - After dinner, reward yourself with a large selection of cocktails at panoramic bar with seasonal pool view, at wine and cigars cellar, or take one of our onboard activities: games, movies, and squid fishing… or relaxing with massage services.
                    
                        <br/>Overnight on board
                    
                        <br/>Day Two: Tai Chi - Breakfast – Sang Toi (Dark & Bright) Cave – Typical Vietnamese cuisine – Farewell at your hotel in Hanoi
                    
                        <br/> 06:30
                        <br/> <br/> Join us for a Tai Chi session on the sundeck for early riser. Tai chi helps focus the mind and condition the body, joining Tai Chi session or do the exercise at fitness area to full charge your energy for the new day.
                    
                        <br/>07:00 – 7:45
                        <br/>Local light breakfast is served
                    
                        <br/> <br/> 08:00
                        <br/>- Mingling yourself in the stunning views of the Bay and it’s dramatic limestone islets as we sail through the calm emerald waters heading to Dark & Bright Cave (Hang Sang & Toi)
                    
                        <br/> <br/> - Take your comfortably on local bamboo boat rowed by the locals to explore the cave.
                    
                        <br/> <br/>09:30
                        <br/>Check out the room
                    
                        <br/> <br/>10:00
                        <br/>Vietnamese cuisine is served.
                    
                        <br/> <br/>12:00
                        <br/>- Disembark from the cruise as our crew say farewell, back to Tuan Chau Marina by our speedboat
                        <br/>- Transfer to ultra-luxurious limousine or MPV Kia Sedona returned to Hanoi (for the guests use our transfer service)
                        <br/>- Arrival back at your hotel approximately 15:00
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="container">
                  <div className="row">
                    <div style={{fontSize:'1.4rem'}}>
                   <br/> Price From US$ 315 +

                   <br/> <br/>  PRICE IN USD: Please contact us for special offer
                   <br/>  Tel: (+84).379309868 - Whatsapp/ Zalo are available.
                   <br/>  Email: info@acoustichotel.com
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("room_dn.location")}
                    <img
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                      className="style-line"
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <p style={{ fontSize: "1.4rem", paddingTop: "10px", textAlign:"justify" }}>
                            {t("room_dn.locationContent")}
                          </p>
                          <div className="hotel__info">
                            <h1>{t("branch.dn")}</h1>
                            <p>
                              <i className="purple fa-solid fa-location-dot mr-2" />
                              {t("room_dn.address")}
                            </p>
                            <p>
                              <i className="purple fa fa-phone mr-2" />
                              {t("room_dn.tel")} : <a className="ml-1 purple" href={`tel:${t("room_dn.phone")}`}>
                              {t("room_dn.phone")}
                              </a>
                            </p>
                            <p>
                              <i className="purple fa fa-envelope mr-2" />
                              {t("room_dn.emailTitle")} : {" "}
                              <a className="ml-1 purple" href={`mailto:${t("room_dn.email")}`}>
                            {t("room_dn.email")}
                             </a>
                            </p>
                          </div>
                        </div>
                        <div className="room__container">
                          <div className="gg-map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.607453222497!2d108.21247711425566!3d16.08584744326651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142181558c6ded1%3A0xd9b07e55bf351cab!2sAzumaya+Hotel+Da+Nang!5e0!3m2!1svi!2s!4v1480501419661"
                              style={{ border: 0 }}
                              allowFullScreen
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </TabPanel>
      </Tabs>
    </>
  );
}
