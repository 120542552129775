import React from "react";
import {useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HelmetLayout from "../HelmetLayout/HelmetLayout";
import ScrollToTop from "../../units/ScrollToTop";
import promotion from "../../image/promotion.jpg"
import Booking from "../../container/Units/Booking";
import BookingMobile from "../../container/Units/BookingMobile";
export default function Contact() {
  const { t, i18n} = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state;

  const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
	const inputRef4 = useRef(null);
  const a = t("branch.dn")
  const b = t("header.title2")
  const c = a + " | "+ b
  const [guestName, setGuestName] = useState('');
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [specialRequest, setSpecialRequest] = useState('');
  const handleKeyDown = (e, ref) => {
		const input = ref.current;    
		const { selectionStart, selectionEnd } = input;
	
		if (e.key === 'ArrowLeft') {
		  e.preventDefault();
		  // Move cursor left
		  if (selectionStart > 0) {
			input.setSelectionRange(selectionStart - 1, selectionStart - 1);
		  }
		} else if (e.key === 'ArrowRight') {
		  e.preventDefault();
		  // Move cursor right
		  if (selectionEnd < input.value.length) {
			input.setSelectionRange(selectionStart + 1, selectionStart + 1);
		  }
		}
	  };

  const [errors, setErrors] = useState({})
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validate = () => { 
    let errors = {}
    let isVaLid = true
  
    if (!guestName) {
      errors.guestName = 'required';
      isVaLid = false
    } 
    if (!email) {
      errors.email = 'required';
      isVaLid = false
  
    } 
    else if (!validateEmail(email)) {
      errors.email = 'Invalid email format';
      isVaLid = false
  
    }
    if (!phone) {
      errors.phone = 'required';
      isVaLid = false
  
    } 
    if (!branch) {
      errors.branch = 'required';
      isVaLid = false
  
    }
    setErrors(errors);
    return isVaLid
  }
  const handleSubmit = async(e) => {
    const dataObject = {
      guestName,
      gender,
      option,
      phone,
      email,
      branch,
      specialRequest,
      language,
      deviceType
    }
    // console.log(dataObject);
    e.preventDefault()
    if(validate()) {
      const token = "73344833-5b52-4403-9255-695907647688"
    // const source = await sendMassageRequest(dataObject, token)
    navigate (`/${language}/`)
    }else{
      alert(`Please ensure that all required fields are completed`)
    }
  }
  return (
    <>
    <ScrollToTop x={0} y={1200} />
    {/* <HelmetLayout title={c}/> */}
    <div className="policies__header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 style={{color: '#fff',fontSize: 52, fontWeight: 'bold' }}>Contact</h1>
          </div>
        </div>
      </div>
    <div>
      <Booking />
    </div>
    </div>
    <BookingMobile />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house" />
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__title" to ={`/${language}/contact/`}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div style={{fontSize: '3rem', fontWeight:'bold', color:'#000071'}}>Ho Guom Hotel</div>
          <div style={{fontSize: '1.4rem'}}>Address:</div>
          <div style={{fontSize: '1.4rem'}}>Phone: </div>
          <div style={{fontSize: '1.4rem'}}>Hotline: </div>
          <div style={{fontSize: '1.4rem'}}>Email:</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
        <form onSubmit={handleSubmit}>
              <h2>Send us an Email</h2>
              <div className="row pl-3 pr-3">
                <div className="col-md-6">
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Name: *</div>
                <input
                 ref={(inputRef1)}
                 onKeyDown={(e) => handleKeyDown(e, inputRef1)}
                  placeholder={t("service_massage.guest_name")}
                  type="text"
                  className={errors.guestName ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={guestName}
                  onChange={(e) => {
                    setGuestName(e.target.value);
                    errors.guestName = ''
                  }}
                />
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Phone Number: *</div>
                 <input
                 ref={(inputRef2)}
                 onKeyDown={(e) => handleKeyDown(e, inputRef2)}
                  type="text"
                  className={errors.phone ? 'form__content col-md-12 validate_failed' : 'form__content col-md-12'}
                  value={phone}
                  placeholder={t("service_massage.phone_number")}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setPhone(e.target.value)
                    errors.phone = ''
                  }
                  }
                />
                <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Email: *</div>
                <input
                   ref={(inputRef3)}
                   onKeyDown={(e) => handleKeyDown(e, inputRef3)}
                  type="text"
                  className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={email}
                  placeholder={t("service_massage.email")}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    errors.email = ''
                  }}
                />
                </div>
              <div className="col-md-6">
              <div style={{fontSize: '1.4rem', fontWeight:"bold"}}>Message: *</div>
                <textarea
                 ref={(inputRef4)}
                 onKeyDown={(e) => handleKeyDown(e, inputRef4)}
                  className="text-note"
                  style={{borderColor: '#c5c5c5'}}
                  cols="40"
                  rows="6"
                  placeholder="Message"
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}
                />
              </div>
              </div>
                <div className="row justify-content-center">
                  <button
                    id="send"
                    class="button-57 send-btn col-2"
                    type="submit"
                  >
                    <span class="text" style={{ color: "#fff" }}>
                      {t('reservation.send')}
                    </span>
                    <span className="d-flex align-item-center">
                      <i class="fa-sharp fa-regular fa-paper-plane" style={{ fontSize: "1.8rem", lineHeight: "2.8rem", color: '#000071' }}></i>
                    </span>
                  </button>
                </div>
              </form>
        </div>
      </div>
    </>
  );
}
