import React from "react";
import {useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classNames from "classnames";
import HelmetLayout from "../../HelmetLayout/HelmetLayout";
import ScrollToTop from "../../../units/ScrollToTop";
import BookingMobile from "../../../container/Units/BookingMobile";
import Booking from "../../../container/Units/Booking";
export default function Room({deviceType}) {
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state;
  const [startDate, setStartDate] = useState(receivedData ? receivedData.startDate : '');
  const [endDate, setEndDate] = useState(receivedData ? receivedData.endDate : '');

  const handleContinue = (selectedRoom) => {
    // if(startDate = '' ){
    // }
    const data = {
      ...receivedData,
      startDate,
      endDate,
      selectedCity,
      selectedBranch,
      selectedRoom
    };
  
    // Navigate to Receiver2Page and pass data
    navigate(`/${language}/reservation/`, { state: data });
  };
  const [selectedCity, setSelectedCity] = useState('hotel-hcm');
  const [selectedBranch, setSelectedBranch] = useState('annex-detail');
  useEffect(() => {
    if (receivedData && receivedData.selectedCity) {
      setSelectedCity(receivedData.selectedCity);
    } else {
      setSelectedCity(selectedCity)
    }
    if (receivedData && receivedData.selectedBranch) {
      setSelectedBranch(receivedData.selectedBranch);
    } else {
      setSelectedBranch(selectedBranch)
    }
  }, [receivedData]);

  const a = "Room & Suite"
  const b = "Ho Guom Hotel"
  const c = a + " | "+ b
  return (
    <>
    <ScrollToTop x={0} y={1200} />
    <HelmetLayout title={c} />
      <div className="service__header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Room & Suites</h1>
            </div>
          </div>
          <Booking />
        </div>
      </div>
      <BookingMobile />
      <div className="container p-0">
        <div className="row">
          <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house" />
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link to = {`/${language}/room/`} className="breadcrumb__title">
                  Room
                </Link>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
      <div className="container">
          <div className="d-flex align-items-center" style={{position: 'relative'}}>
            <div className="d-flex justify-content-center align-items-center room-info">
              <div>
              <div style={{fontWeight:'bold', fontSize: '3rem', color:'#000071'}}>SUITE</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>From: USD/NIGHT</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>ROOM PLAN: 01 Living room + 01 bedroom</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>BED: 01 King bed</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>MAX: 02 Adults + 01 Child</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>SIZE: 70 m²</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>View: City View - Large balcony</div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>Price: Inclusive: service charge & Taxes, drink in room's minibar</div>
              <div className="d-flex mt-2">
              <div className="col-md-4 d-flex justify-content-center align-items-center read-more-2">
                            <Link style={{textDecoration: 'none', padding:10}} to = {`/${language}/room/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            VIEW DETAIL
                           </span>
                           </Link>
                      </div>
                      <div className="col-md-4 ml-2 d-flex justify-content-center align-items-center read-more">
                            <Link style={{textDecoration: 'none', padding:10}} to = {`/${language}/room-detail/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                           BOOK NOW
                           </span>
                           </Link>
                      </div>
              </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <div className="room-image" style={{height: 500}}>
            <img src="https://shingolf.vn/image/hoguomhotel/room/Suite/picture1.png" />
              </div> 
            </div>
          </div>
        </div>
        <div className="container" style={{marginTop: 150}}>
        <div className="d-flex align-items-center" style={{position: 'relative'}}>
            <div className="d-flex justify-content-center align-items-center">
            <div className="room-image-2" style={{height: 500}}>
            <img src="https://shingolf.vn/image/hoguomhotel/room/Deluxe%20city%20view%20room/picture1.png" />
              </div> 
            <div className="d-flex justify-content-center align-items-center room-info-2">
              <div>
              <div style={{fontWeight:'bold', fontSize: '3rem', color:'#000071'}}>DELUXE</div>
              <br/>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>From: <span style={{fontWeight: 'lighter'}}>USD/NIGHT</span></div>
              <br/>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>ROOM PLAN: <span style={{fontWeight: 'lighter'}}>01 Living room + 01 bedroom</span></div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>BED: <span style={{fontWeight: 'lighter'}}>01 King bed</span></div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>MAX: <span style={{fontWeight: 'lighter'}}>02 Adults + 01 Child</span></div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>SIZE: <span style={{fontWeight: 'lighter'}}>70 m²</span></div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>View: <span style={{fontWeight: 'lighter'}}>City View - Large balcony</span></div>
              <div style={{fontWeight:'bold', color:'#000', fontSize: deviceType === "pc" ? '1.4rem' : '1.1rem'}}>Price: <span style={{fontWeight: 'lighter'}}>Inclusive: service charge & Taxes, drink in room's minibar</span></div>
               <div className="d-flex mt-2">
              <div className="col-md-4 d-flex justify-content-center align-items-center read-more-2">
                            <Link style={{textDecoration: 'none', padding:10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                            VIEW DETAIL
                           </span>
                           </Link>
                      </div>
                      <div className="col-md-4 ml-2 d-flex justify-content-center align-items-center read-more">
                            <Link style={{textDecoration: 'none', padding:10}} to = {`/${language}/massage/`}>
                           <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                           BOOK NOW
                           </span>
                           </Link>
                      </div>
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
    </>
  );
}
