import {useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
export default function BookingMobile (){
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const city = t('booking.city', {returnObjects:true})
    const branch = t('booking.branch', {returnObjects:true})
    const language = i18n.language || 'en'
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [guest, setGuest] = useState(1);
    const handleSendData = () => {
        const data = {
            startDate: startDate ,
            // ? format(startDate, 'yyyy-MM-dd'): '',
            endDate: endDate ,
            //  format(endDate,'yyyy-MM-dd'): '',
        }
        navigate(`/${language}/${selectedBranch}/room/`,{state: data})
    }
    return(
        <div>              
        <div className="content__booking-2">
        <div className="container">
            <div className="row pt-0 pl-0">
            <div className="col-md-12">
              <h1 style={{marginTop: 10}}><i class="fa-regular fa-circle-check"></i> ROOM & RATE</h1>
            </div>
                <div className="col-md-12">
                  <Flatpickr 
                  value={startDate}
                  options={{
                    minDate:'today',
                    dateFormat:'Y-m-d'}} 
                  className="col-md-12 content__booking-date webkit-appearance" 
                  placeholder={t('booking.date_in')} 
                  onChange={(dates)=>{
                    setStartDate(dates[0]);
                    }} />
                    </div>
                <div className="col-md-12">
                  <Flatpickr 
                  value={endDate}
                  options={{
                    minDate:new Date(startDate),
                    dateFormat:'Y-m-d'}} 
                  className="col-md-12 content__booking-date webkit-appearance" 
                  placeholder={t('booking.date_out')} 
                  onChange={(dates) =>{
                    setEndDate(dates[0]);
                  }} />
                  </div>
                <div className="col-md-12">
                <div className="content__booking-branch">
                    <input 
                    className="content__booking-branch-select"
                    type="number"
                    value={guest}
                    onChange= {(e) => setGuest(e.target.value)}
                    />
                </div>
                </div>
                <div className="col-md-12">
                <button
                onClick={handleSendData}
                className="base__btn btn--mobile" style={{marginTop:10}}>{t('booking.reserve')}
                </button> 
                </div>  
                </div>
            </div>
         </div>
        </div>
    )
    
}
