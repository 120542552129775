import React from 'react';
import { Routes, Route, useParams, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Home from "./container/Home/Home";
import TourInfo from './container/TourInfo/TourInfo.jsx';
import TourDetail from './components/BranchDetail/TourDetail/TourDetail.jsx';
import Policies from "./container/Policies/Policies";
import Contract from "./container/Contract/Contract";
import Reservation from './container/Reservation/Reservation.jsx';
import Room from "./components/BranchDetail/Room/room.jsx";
import DNRoomDetail from "./components/BranchDetail/TourDetail/TourDetail.jsx";
import ThankYou from './container/Reservation/ThankYou';
import VietnamService from "./container/Service/Service"
import ErrorPage from './container/Units/ErrorPage';
import Reservation_backup from './container/Reservation/Reservation_backup'
import ThankYouService from './container/Service/ThankYou';
import Promotion from './container/Promotion/Promotion.jsx';
import Cookies from "js-cookie";
import Bowser from 'bowser';
import HomeTest from './container/Home/HomeTest'
import CookieConsent from './components/Cookies/CookiesConsent';
import CookiePolicy from './components/Cookies/CookiePolicy';
import Test from './Test/Test.jsx';
import Privacy from './components/Cookies/Privacy.jsx';
import Recruitment from './container/Recruitment/Recruitment.jsx';
import Package from './container/Package/Package.jsx';
import AboutUs from './components/AboutUs/AboutUs.jsx';
import Gallery from './components/Gallery/Gallery.jsx';
import Contact from './components/Contact/Contact.jsx';
import RoomDetail from './components/BranchDetail/Room/RoomDetail.jsx';
function NewRouter(props) {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const defaultLanguage = Cookies.get('selectedLanguage') || i18n.language || 'en';
    const [deviceType, setDeviceType] = useState('');
      useEffect(() => {
        const handleResize = () => {
          const width = window.innerWidth;
          if (width >= 1024) {
            setDeviceType('pc');
          } else if (width >= 768) {
            setDeviceType('tablet');
          } else {
            setDeviceType('phone');
          }
        };
        handleResize(); 
        window.addEventListener('resize', handleResize); 
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      useEffect(() => {
        const supportedLanguages = ['en', 'ja', 'vi', 'ko'];
        const pathSegments = location.pathname.split('/');
        const languageFromPath = pathSegments[1];
    
        if (supportedLanguages.includes(languageFromPath)) {
          if (i18n.language !== languageFromPath) {
            i18n.changeLanguage(languageFromPath);
          }
        } else {
          const defaultLanguage = Cookies.get('i18next') || navigator.language.split('-')[0];
          const languageToUse = supportedLanguages.includes(defaultLanguage) ? defaultLanguage : 'en';
          navigate(`/${languageToUse}${location.pathname}`, { replace: true });
        }
      }, [location.pathname, i18n, navigate]);

      const [browser, setBrowser] = useState('');
      useEffect(() => {
        const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
        setBrowser(browserName);
      }, []);

    return (
        <section>
            <Routes>
                <Route path="/" element={<Navigate to={`/${i18n.language}/`} replace />} />
                <Route path= "/:lang/" element={<Home deviceType={deviceType} browser={browser} />} />
                <Route path = "/:lang/service/" element={<VietnamService />} />
                <Route path = "/:lang/about/" element={<AboutUs />} />
                <Route path = "/:lang/q&a/" element={<Policies />} />
                <Route path = "/:lang/gallery/" element={<Gallery />} />
                <Route path = "/:lang/reservation/" element={<Reservation deviceType={deviceType} browser={browser}/>} />
                <Route path = "/:lang/tour/" element={<TourInfo/>} />
                <Route path = "/:lang/tour-detail/" element={<TourDetail/>} />
                <Route path = "/:lang/special-package/" element={<Package/>} />
                <Route path = "/:lang/service/" element={<VietnamService deviceType={deviceType}/>} />
                <Route path = "/:lang/contract/" element={<Contract />} />
                <Route path = "/:lang/thank-you/:selectedCity/" element={<ThankYou />} />
                <Route path = "/:lang/massage/thank-you/:selectedCity/" element={<ThankYouService />} />
                <Route path = "/:lang/promotion/" element={<Promotion />} />
                <Route path = "/:lang/contact/" element={<Contact />} />
                <Route path = "/:lang/room/" element={<Room deviceType={deviceType} />} />
                <Route path = "/:lang/room-detail/" element={<RoomDetail deviceType={deviceType} />} />
                <Route path = "/:lang/gallery/" element={<DNRoomDetail />} />   
                <Route path = '/:lang/dev-test' element={<Reservation_backup deviceType={deviceType} browser={browser}/>} />
                <Route path ='/:lang/cookie-policy/' element={<CookiePolicy />} />
                <Route path ='/:lang/privacy/' element={<Privacy />} />  
                <Route path='/:lang/recruitment' element={<Recruitment />} />
                <Route path ='/:lang/test' element={<Test />} />
              </Routes>
        </section>
    );
}
export default NewRouter;